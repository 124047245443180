import Image from "next/image";
import Link from "next/link";

const TripCard = ({ trip }) => {
  const tripUrl = trip.title.toLowerCase().split(" ").join("-");

  return (
    <Link target="_blank" href={`https://trip.hamsfly.com/trips/${tripUrl}`}>
      <div className="group mx-auto min-w-[300px] md:min-w-0 bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] hover:shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] duration-300 rounded-lg overflow-hidden cursor-pointer">
        <div className="overflow-hidden">
          <Image
            src={trip.images[0]}
            alt={trip.title}
            width={1000}
            height={1000}
            className="w-full h-[230px] object-cover group-hover:scale-105 duration-500"
          />
        </div>

        <div className="p-4 pb-5 space-y-2">
          <h2 className="text-[#282828] text-lg">{trip.title}</h2>
          <div>
            <p className="text-sm text-gray-600">{trip.duration}</p>
          </div>

          <div className="pt-3">
            <div className=" text-white p-1.5 rounded-full w-fit px-5 bg-[#009D4D]">
              BDT {parseInt(trip.price).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TripCard;
