import useTranslator from "@/hooks/useTranslator";

const FlightDestinations = () => {
  const texts = {
    txt1: "Book flights to top destinations",
  };

  const translator = useTranslator(texts);

  // Function to generate a random date between two dates
  const getRandomDate = () => {
    const start = new Date();
    const end = new Date();
    end.setMonth(end.getMonth() + 6); // Setting end date to 6 months ahead

    const randomDate = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return randomDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
  };

  // Destinations data
  const destinations = {
    "North America": { city: "New York", code: "JFK" },
    "South America": { city: "Buenos Aires", code: "EZE" },
    Europe: { city: "Rome", code: "FCO" },
    Asia: { city: "Tokyo", code: "HND" },
  };

  return (
    <section data-aos="fade-up">
      <h3 className="text-2xl md:text-3xl font-semibold text-center mb-8">
        {translator.txt1}
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {Object.keys(destinations).map((region, index) => {
          const { city, code } = destinations[region];
          const randomDate = getRandomDate();
          const searchPath = `/flights/results?departure=DXB&arrival=${code}&date=${randomDate}&adults=1`;

          return (
            <div key={index} className="space-y-2">
              <div
                style={{ backgroundColor: "#00703E" }}
                className="text-white p-4 rounded-lg text-lg font-semibold text-center"
              >
                {region}
              </div>

              <a
                href={searchPath}
                target="_blank"
                rel="noopener noreferrer"
                className="block bg-blue-100 text-green-800 p-3 rounded-lg font-semibold text-center hover:bg-blue-200 transition"
              >
                {`Flights to ${city}`}
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FlightDestinations;
