"use client";
import TravelarCounter from "@/components/homeComponent/banner/TravelarCounter";

import OnWay from "@/components/homeComponent/banner/OnWay";
import RoundWay from "@/components/homeComponent/banner/Return";
import { setDestinationType } from "@/redux/itinerarySlice";
import { useDispatch, useSelector } from "react-redux";
import useTranslator from "@/hooks/useTranslator";

const FlightBanner = () => {
  const dispatch = useDispatch();
  const { destinationType } = useSelector((state) => state.itineraries);

  const texts = {
    welcome: "Best Sites For Cheap Flights with the right price.",
    description: " with worldwide partners.",
  };

  const translator = useTranslator(texts);

  return (
    <div
      style={{
        backgroundImage: `url('https://i.ibb.co/bQMK0c3/HAMS-fly-Banner.webp')`,
      }}
      className="h-auto min-h-screen bg-cover bg-center bg-no-repeat relative w-full"
    >
      <span className="absolute w-full h-full bg-black/20 pointer-events-none"></span>
      <div className="relative flex flex-col items-center pt-24 md:pt-36 pb-8 justify-center z-50 px-4 md:px-7">
        <div className="text-white text-center mt-5 space-y-4">
          <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold">
            {translator.welcome || texts.welcome}
          </h1>
          <p className="text-base md:text-lg font-medium">
            {translator.description || texts.description}
          </p>
          <div className="w-full md:w-2/3 lg:w-auto bg-black/50 mx-auto py-6 px-4 sm:px-8 rounded">
            <div className="w-full mx-auto">
              <div className="flex flex-wrap gap-2 md:gap-4 mb-4 w-auto">
                <div className="relative w-full md:w-64">
                  <select
                    value={destinationType}
                    onChange={(e) => {
                      console.log("Selected:", e.target.value);
                      dispatch(setDestinationType(e.target.value));
                    }}
                    className="w-full bg-[#e1eee8] text-black rounded-lg px-4 py-2 outline-none text-sm font-medium"
                  >
                    <option value="one">One Way</option>
                    <option value="round">Round Way</option>
                  </select>
                </div>
                <TravelarCounter />
              </div>

              {destinationType === "round" ? <RoundWay /> : <OnWay />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightBanner;
