"use client";
import React, { useState } from "react";
import useTranslator from "@/hooks/useTranslator";

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const texts = {
    title: "FAQs Cheap Get Air Ticket's",
    q1: "How do I find the best sites for finding cheap flights deals on HAMS Fly?",
    a1: "Simply enter your travel details (destination, dates, number of passengers) into our search tool, and we will show you the best options from a variety of airlines and travel agents. You can filter results by price, duration, or preferred airlines to find the perfect flight for you.",
    q2: "Can I book flights for multiple passengers at once?",
    a2: "Yes! HAMS Fly makes it easy to book flights for multiple passengers at the same time. Just enter the number of travelers and we will display options for all of you. You can even choose seats together for a smoother experience.",
    q3: "Are there any hidden fees when I book my flight?",
    a3: "No, we believe in transparency. The price you see is the price you pay. There are no hidden fees or surprise charges when booking with HAMS Fly. You will see the total cost upfront before confirming your booking",
    q4: "How do I change or cancel my flight after booking?",
    a4: "If you need to change or cancel your flight, please contact our customer support team as soon as possible. They will guide you through the process and help you with any changes, subject to airline policies. Be aware that some changes may involve additional fees.",
    q5: "How does HAMS Fly work?",
    a5: "At HAMS Fly, we make booking your flights easy, affordable, and stress-free. Here is how it works: Search for Flights: Start by entering your travel details—destination, dates, number of passengers—into our simple search tool. Our platform will quickly show you a wide range of flight options from top airlines and trusted travel agents. Book Your Flight: Once you have found the flight that works for you, book it with just a few clicks. Enjoy the Best Deals: Hamsfly offers exclusive discounts and promotions to make sure you get the best price. You can even find last-minute deals, discounted first-class tickets, and flexible options to fit your travel plans. Customer Support Anytime: Our customer service team is available to assist you 24/7. Whether you need help booking your flight, making changes, or managing your booking, we are here to help every step of the way.",
  };

  const translator = useTranslator(texts);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqs = [
    {
      question: translator.q1 || texts.q1,
      answer: translator.a1 || texts.a1,
    },
    {
      question: translator.q2 || texts.q2,
      answer: translator.a2 || texts.a2,
    },
    {
      question: translator.q3 || texts.q3,
      answer: translator.a3 || texts.a3,
    },
    {
      question: translator.q4 || texts.q4,
      answer: translator.a4 || texts.a4,
    },
    {
      question: translator.q5 || texts.q5,
      answer: translator.a5 || texts.a5,
    },
  ];

  return (
    <div data-aos="fade-up" className="space-y-0 relative">
      <div className="container max-w-[1200px] mx-auto px-4 lg:px-0">
        <h2 className="text-2xl font-bold mb-5">
          {translator.title || texts.title}
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => {
            const isActive = activeIndex === index;

            return (
              <div
                key={index}
                className={`border rounded-md p-1 cursor-pointer transition-all duration-1000 ${
                  isActive ? "bg-gray-100" : ""
                }`}
                onClick={() => toggleFAQ(index)}
                role="button"
                tabIndex={0}
                aria-expanded={isActive}
                onKeyDown={(e) => e.key === "Enter" && toggleFAQ(index)}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium">{faq.question}</h3>
                  <span className="text-xl font-bold">
                    {isActive ? "-" : "+"}
                  </span>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    isActive ? "max-h-[300px] mt-4" : "max-h-0"
                  }`}
                  style={{
                    height: isActive ? "auto" : "0px",
                  }}
                >
                  <p className="text-gray-700">{faq.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
