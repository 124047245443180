"use client";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useTranslator from "@/hooks/useTranslator";

const PopularDestinations = () => {
  const [isClient, setIsClient] = useState(false);

  const texts = {
    title: "Popular Destinations for Business Class Deals",
    nyToLondon: "New York to London",
    nyToLondonDesc:
      "Fly in style and arrive refreshed for your meeting or vacation.",
    laToTokyo: "Los Angeles to Tokyo",
    laToTokyoDesc:
      "Enjoy a seamless journey with premium service on long-haul flights.",
    dubaiToSydney: "Dubai to Sydney",
    dubaiToSydneyDesc:
      "Make your trip unforgettable with luxury travel at a fraction of the cost.",
    riyadhToNy: "Riyadh to New York",
    riyadhToNyDesc: "Fly with onboard airlines privilege.",
  };

  const translator = useTranslator(texts);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  const getRandomDate = () => {
    const today = new Date();
    const randomDays = Math.floor(Math.random() * 24) + 7;
    const futureDate = new Date(today.setDate(today.getDate() + randomDays));
    return futureDate.toISOString().split("T")[0];
  };

  const randomDate = getRandomDate();

  const destinations = [
    {
      departure: "JFK",
      arrival: "LHR",
      label: translator.nyToLondon || texts.nyToLondon,
      text: translator.nyToLondonDesc || texts.nyToLondonDesc,
      icon: "🌍",
    },
    {
      departure: "LAX",
      arrival: "HND",
      label: translator.laToTokyo || texts.laToTokyo,
      text: translator.laToTokyoDesc || texts.laToTokyoDesc,
      icon: "✈️",
    },
    {
      departure: "DXB",
      arrival: "SYD",
      label: translator.dubaiToSydney || texts.dubaiToSydney,
      text: translator.dubaiToSydneyDesc || texts.dubaiToSydneyDesc,
      icon: "🛫",
    },
    {
      departure: "RUH",
      arrival: "JFK",
      label: translator.riyadhToNy || texts.riyadhToNy,
      text: translator.riyadhToNyDesc || texts.riyadhToNyDesc,
      icon: "🌟",
    },
  ];

  return (
    <section className="popular-destinations py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-left text-primary mb-8 text-2xl font-bold">
          {translator.title || texts.title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {destinations.map((item, index) => (
            <motion.a
              href={`/flights/results?departure=${item.departure}&arrival=${item.arrival}&date=${randomDate}&adults=1`}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className="p-6 bg-white shadow-md rounded-lg flex items-start space-x-4 hover:shadow-xl transition-shadow"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <motion.div
                className="text-4xl"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                {item.icon}
              </motion.div>
              <div>
                <motion.h3
                  className="text-lg font-semibold text-gray-800"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.2 }}
                >
                  {item.label}
                </motion.h3>
                <motion.p
                  className="text-gray-600 mt-2"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.3 }}
                >
                  {item.text}
                </motion.p>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularDestinations;
