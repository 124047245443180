"use client";
import React, { useEffect, useState } from "react";
import TripCard from "./TripCard";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import CardSkeleton from "./CardSkeleton";
import useTranslator from "@/hooks/useTranslator";
import { useTranslation } from "react-i18next";

const ExploreSection = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosURL = globalAxiosURL();
  const { t } = useTranslation();

  const texts = {
    txt1: "The Places You Must Visit",
    txt2: "Visit new places and chosen destinations of your interests with",
  };
  const translator = useTranslator(texts);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const { data } = await axiosURL.get("/trip-packages");
        setTrips(data.data);
      } catch (error) {
        console.error("Error fetching trips:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTrips();
  }, []);

  return (
    <div data-aos="fade-up" className="max-w-[1200px] mx-auto px-4 md:px-0">
      <div className="text-center mb-4">
        <h1 className="text-3xl p-1">{translator.txt1 || texts.txt1}</h1>
        <div className="p-1">
          <p className="text-xl">{translator.txt2 || texts.txt2} HAMS Fly.</p>
        </div>
      </div>
      <div className="flex md:grid grid-cols-3 gap-10 mt-16 overflow-x-auto md:overflow-visible">
        {loading
          ? Array.from({ length: 3 }).map((_, i) => <CardSkeleton key={i} />)
          : trips.map((trip) => <TripCard key={trip._id} trip={trip} />)}
      </div>
    </div>
  );
};

export default ExploreSection;
