"use client";
import React from "react";
import PopularDestinations from "../homeComponent/PopularDestinations";
import SpecialOffers from "@/components/flightsHome/SpecialOffers";
import FlightDestinations from "@/components/homeComponent/ui/topdestinations";
import {
  Plane,
  Briefcase,
  UtensilsCrossed,
  DollarSign,
  HeadphonesIcon,
} from "lucide-react";
import useTranslator from "@/hooks/useTranslator";


const ExternalLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="mx-1 font-semibold text-[#00703E] hover:underline"
  >
    {children}
  </a>
);

const SectionTitle = ({ title, className = "" }) => (
  <h3 className={`text-2xl md:text-3xl font-semibold mb-4 ${className}`}>
    {title}
  </h3>
);

const BulletList = ({ items }) => (
  <ul className="space-y-4">
    {items.map((item, index) => (
      <li key={index} className="flex items-start">
        <span className="mr-2 mt-1 text-[#00703E]">•</span>
        <span>
          <span className="font-semibold">{item.label}:</span> {item.text}
        </span>
      </li>
    ))}
  </ul>
);

const AboutHams = () => {
  const texts = {
    txt1: "Cheap International Business Class Flights with HAMS fly",
    at: "At",
    txt2: "we specialize in making luxury travel accessible to all. Because international business class flights are all about comfort, convenience, and convenience. Whether you are traveling for business or leisure, we always offer you the best deals on premium travel.",
    txt3: "Why choose us for cheap international business class flights?",
    txt4: "Exclusive Discounts",
    txt5: "We negotiate directly with airlines to bring you unbeatable prices on business class tickets.",
    txt6: "Tailored Options",
    txt7: "Our travel experts customize itineraries to suit your schedule and budget, so you get the best value for your trip.",
    txt8: "Premium Comfort",
    txt9: "Enjoy spacious seats, gourmet cuisine, and world-class service on your international flights without paying full price.",
    txt10: "No Hidden Fees",
    txt11:
      "What you see is what you pay! We believe in transparency, so we ensure that all pricing is clear and upfront, with no surprise charges.",
    txt12: "24/7 Support",
    txt13:
      "From booking to boarding, our dedicated team is here to assist you every step of the way.",
    txt14: "Why Book Cheap 1st Class Flights With Us?",
    txt15: "Exclusive Discounts",
    txt16:
      "We partner with airlines to provide unbeatable first-class flight deals.",
    txt17: "Luxurious Comfort",
    txt18:
      "Enjoy spacious seating, gourmet meals, and access to premium lounges.",
    txt19: "Personalized Assistance",
    txt20:
      "Our travel experts tailor your journey to fit your preferences and schedule.",
    txt21: "Priority Services",
    txt22: "Benefit from priority boarding, check-in, and baggage handling.",
    txt23: "Flexible Routes",
    txt24: "Find the best routes and timings for your convenience and comfort.",
    txt25: "Popular Destinations for Cheap First Class Flights",
    txt26: "New York to Paris",
    txt27:
      "Fly in style to the city of lights and enjoy unparalleled comfort along the way.",
    txt28: "San Francisco to Hong Kong",
    txt29:
      "Travel long-haul in complete luxury with spacious cabins and premium amenities.",
    txt30: "Miami to Dubai",
    txt31:
      "Indulge in a world-class flying experience with top-tier airlines and exceptional service.",
    txt32: "Book flights to top destinations",
    txt33: "How We Offer Affordable Cheap First Class Flight Tickets",
    txt34:
      "Our team uses insider connections, advanced booking strategies, and last-minute upgrade opportunities to bring you incredible savings on cheap first-class flight tickets. We focus on delivering luxury travel at prices that fit your budget.",
    txt35:
      "Enjoy spacious seating, gourmet meals, priority boarding, and world-class service, all at a price you won't believe. Through our partnerships with leading airlines, we secure discounted fares so you can travel in style without breaking the bank.",
    txt36:
      "Book your cheap first-class flight with Hamsfly today and treat yourself to the ultimate flying experience at unbeatable prices.",
    txt37: "Insider Connections",
    txt38: "We use our industry ties to secure exclusive discounts and offers.",
    txt39: "Luxury at Your Fingertips",
    txt40: "Enjoy gourmet meals, spacious seating, and world-class service.",
    txt41: "Seamless Booking",
    txt42:
      "Quickly compare prices and secure your first-class seat in minutes.",
    txt43: "Book Your Cheap First Class Flights Today!",
    txt44:
      "Don't let high prices keep you from experiencing the ultimate in travel luxury. Visit HAMS Fly or call",
    txt45: "08802-48957730, Hotline 088014-07020590",
    txt46: "to secure your cheap first class flights now.",
    txt47: "WhatsApp",
    txt48: "Best Sites for the Most Expensive Flight Ticket Deals",
    txt49: "Unbeatable Offers",
    txt50:
      "Access exclusive deals from leading airlines and travel agents, curated just for you.",
    txt51: "Compare & Book",
    txt52:
      "Find the best flight tickets easily with our user-friendly platform and compare deals.",
    txt53: "Save Big",
    txt54:
      "Don't miss our time-sensitive offers with daily deals and flash sales.",
    txt55: "Hassle-Free Booking",
    txt56:
      "Book your trip in a few simple steps and enjoy peace of mind knowing you got the best deal.",
    txt57: "Trusted Partners",
    txt58:
      "Collaborating with top airlines and travel agents to bring you exclusive discounts.",
    txt59: "Luxury for Less",
    txt60:
      "Experience first-class travel at economy prices with our unbeatable offers.",
    txt61: "Find Cheap Flights for All with HAMS Fly",
    txt62:
      "Experience the luxury of travel without the hefty price tag. Affordable first-class options for everyone!",
    txt63: "Luxury for Less",
    txt64:
      "Enjoy premium comfort, spacious seating, and gourmet meals at an unbelievable price.",
    txt65: "Exclusive Deals",
    txt66:
      "Access special discounts and promotions for domestic and international flights.",
    txt67: "Seamless Booking",
    txt68:
      "Book your first-class flight in minutes using our easy-to-navigate platform.",
    txt69: "Trusted Partners",
    txt70:
      "Collaborating with top airlines to secure the best fares for luxury travel.",
    txt71: "Customer Satisfaction",
    txt72:
      "Our priority is your happiness. We make your luxury travel dreams a reality.",
  };

  const translator = useTranslator(texts);

  return (
    <div className="max-w-7xl mx-auto space-y-16 px-4 py-12 sm:px-6 lg:px-8">
      <section data-aos="fade-up">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 bg-gray-50 rounded-xl p-8 shadow-lg">
          {translator.txt1 || texts.txt1}
        </h2>

        <div className="mt-12 bg-gray-50 rounded-xl p-8 shadow-lg">
          <p>
            {translator.at || texts.at}
            <ExternalLink href="https://hamsfly.com">
              HAMS Fly Limited
            </ExternalLink>
            , {translator.txt2 || texts.txt2}
          </p>
        </div>

        <div className="mt-12 bg-gray-50 rounded-xl p-8 shadow-lg">
          <h3 className="text-2xl md:text-3xl font-semibold text-center mb-8">
            {translator.txt3 || texts.txt3}
          </h3>

          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                icon: DollarSign,
                label: translator.txt4 || texts.txt4,
                text: translator.txt5 || texts.txt5,
              },
              {
                icon: Briefcase,
                label: translator.txt6 || texts.txt6,
                text: translator.txt7 || texts.txt7,
              },
              {
                icon: UtensilsCrossed,
                label: translator.txt8 || texts.txt8,
                text: translator.txt9 || texts.txt9,
              },
              {
                icon: Plane,
                label: translator.txt10 || texts.txt10,
                text: translator.txt11 || texts.txt11,
              },
              {
                icon: HeadphonesIcon,
                label: translator.txt12 || texts.txt12,
                text: translator.txt13 || texts.txt13,
              },
            ].map((item, index) => (
              <div key={index} className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="bg-blue-100 p-3 rounded-full">
                    <item.icon className="w-6 h-6 text-[#00703E]" />
                  </div>
                </div>
                <div>
                  <h4 className="text-lg font-semibold mb-2">{item.label}</h4>
                  <p className="text-gray-600">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div>
        <PopularDestinations />
      </div>

      <section data-aos="fade-up">
        <SpecialOffers />
      </section>

      <section data-aos="fade-up" className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <SectionTitle
            title={translator.txt14 || texts.txt14}
            className="text-center text-3xl font-bold text-gray-800 mb-8"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: translator.txt15 || texts.txt15,
                description: translator.txt16 || texts.txt16,
                icon: "💸",
              },
              {
                title: translator.txt17 || texts.txt17,
                description: translator.txt18 || texts.txt18,
                icon: "🛋️",
              },
              {
                title: translator.txt19 || texts.txt19,
                description: translator.txt20 || texts.txt20,
                icon: "🤝",
              },
              {
                title: translator.txt21 || texts.txt21,
                description: translator.txt22 || texts.txt22,
                icon: "🚀",
              },
              {
                title: translator.txt23 || texts.txt23,
                description: translator.txt24 || texts.txt24,
                icon: "🗺️",
              },
              {
                title: translator.txt12 || texts.txt12,
                description: translator.txt13 || texts.txt13,
                icon: "📞",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="p-6 bg-white shadow-md rounded-lg flex items-start space-x-4 hover:shadow-xl transition-shadow"
              >
                <div className="text-4xl">{item.icon}</div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 mt-2">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <SectionTitle
            title={translator.txt25 || texts.txt25}
            className="text-left text-3xl font-bold text-gray-800 mb-8"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                path: "/flights/results?departure=JFK&arrival=CDG&date=2025-03-08&adults=1",
                label: translator.txt26 || texts.txt26,
                text: translator.txt27 || texts.txt27,
              },
              {
                path: "/flights/results?departure=LAX&arrival=HND&date=2025-03-08&adults=1",
                label: translator.txt28 || texts.txt28,
                text: translator.txt29 || texts.txt29,
              },
              {
                path: "/flights/results?departure=MIA&arrival=DXB&date=2025-03-08&adults=1",
                label: translator.txt30 || texts.txt30,
                text: translator.txt31 || texts.txt31,
              },
            ].map((item, index) => (
              <a
                key={index}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-6 bg-white shadow-md rounded-lg hover:shadow-xl transition-shadow"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {item.label}
                </h3>
                <p className="text-gray-600">{item.text}</p>
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* <section data-aos="fade-up">
        <h3 className="text-2xl md:text-3xl font-semibold text-center mb-8">
          {translator.txt32 || texts.txt32}
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {["North America", "South America", "Europe", "Asia"].map(
            (region, index) => (
              <div key={index} className="space-y-2">
                <div
                  style={{ backgroundColor: "#00703E" }}
                  className="text-white p-4 rounded-lg text-lg font-semibold text-center"
                >
                  {region}
                </div>
                <div className="bg-blue-100 text-#00703E p-3 rounded-lg font-semibold text-center">
                  {`Flights to ${
                    region === "North America"
                      ? "New York"
                      : region === "South America"
                      ? "Argentina"
                      : region === "Europe"
                      ? "Rome"
                      : "Tokyo"
                  }`}
                </div>
              </div>
            )

          )}
        </div>
      </section> */}

      <section data-aos="fade-up">
        <FlightDestinations />
      </section>

      <section
        data-aos="fade-up"
        className="py-16 bg-gradient-to-r from-[#00703E] to-[#004F2C] text-white"
      >
        <div className="container mx-auto px-6">
          <SectionTitle
            title={translator.txt33 || texts.txt33}
            className="text-center text-4xl font-extrabold text-white mb-8"
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center text-white">
              <p className="text-lg text-gray-200 leading-relaxed mb-4">
                {translator.txt34 || texts.txt34}
              </p>
              <p className="text-lg text-gray-200 leading-relaxed mb-4">
                {translator.txt35 || texts.txt35}
              </p>
              <p className="text-lg text-gray-200 leading-relaxed">
                {translator.txt36 || texts.txt36}
              </p>
            </div>

            <div className="relative">
              <div className="p-6 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-transform mb-6">
                <h3 className="text-xl font-semibold text-[#00703E] mb-2">
                  ✈️ {translator.txt37 || texts.txt37}
                </h3>
                <p className="text-gray-700">
                  {translator.txt38 || texts.txt38}
                </p>
              </div>
              <div className="p-6 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-transform mb-6">
                <h3 className="text-xl font-semibold text-[#00703E] mb-2">
                  🌟 {translator.txt39 || texts.txt39}
                </h3>
                <p className="text-gray-700">
                  {translator.txt40 || texts.txt40}
                </p>
              </div>
              <div className="p-6 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-transform">
                <h3 className="text-xl font-semibold text-[#00703E] mb-2">
                  🕒 {translator.txt41 || texts.txt41}
                </h3>
                <p className="text-gray-700">
                  {translator.txt42 || texts.txt42}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        className="bg-blue-50 rounded-xl p-8 shadow-lg"
      >
        <div className="text-center">
          <h3 className="text-3xl font-bold text-black mb-4">
            {translator.txt43 || texts.txt43}
          </h3>
          <p className="text-lg text-gray-700 mb-6">
            {translator.txt44 || texts.txt44}{" "}
            <span className="font-semibold text-black">
              {translator.txt45 || texts.txt45}
            </span>{" "}
            {translator.txt46 || texts.txt46}
          </p>
          <a
            href="https://wa.me/01407020590"
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 text-white text-lg font-medium rounded-lg shadow-md transition-colors"
            style={{
              backgroundColor: "#00703E",
            }}
          >
            {translator.txt47 || texts.txt47}
          </a>
        </div>
      </section>

      <section className="py-16 bg-white" data-aos="fade-up">
        <div className="max-w-6xl mx-auto px-6">
          <SectionTitle
            title={translator.txt48 || texts.txt48}
            className="text-center text-4xl font-extrabold text-gray-900 mb-12"
          />

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-[#00703E] text-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">
                  {translator.txt49 || texts.txt49}
                </h3>
                <p className="text-base">{translator.txt50 || texts.txt50}</p>
              </div>
            </div>

            <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                  {translator.txt51 || texts.txt51}
                </h3>
                <p className="text-base text-gray-700">
                  {translator.txt52 || texts.txt52}
                </p>
              </div>
            </div>

            <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                  {translator.txt53 || texts.txt53}
                </h3>
                <p className="text-base text-gray-700">
                  {translator.txt54 || texts.txt54}
                </p>
              </div>
            </div>

            <div className="bg-[#00703E] text-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">
                  {translator.txt55 || texts.txt55}
                </h3>
                <p className="text-base">{translator.txt56 || texts.txt56}</p>
              </div>
            </div>

            <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                  {translator.txt57 || texts.txt57}
                </h3>
                <p className="text-base text-gray-700">
                  {translator.txt58 || texts.txt58}
                </p>
              </div>
            </div>

            <div className="bg-[#00703E] text-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">
                  {translator.txt59 || texts.txt59}
                </h3>
                <p className="text-base">{translator.txt60 || texts.txt60}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-16 bg-gradient-to-br from-[#00703E] to-[#004F2C] text-white"
        data-aos="fade-up"
      >
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-extrabold">
              {translator.txt61 || texts.txt61}
            </h2>
            <p className="mt-4 text-lg font-light">
              {translator.txt62 || texts.txt62}
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="bg-[#00703E] text-white rounded-full p-3 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </span>
                <h3 className="text-xl font-semibold">
                  {translator.txt63 || texts.txt63}
                </h3>
              </div>
              <p>{translator.txt64 || texts.txt64}</p>
            </div>

            <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="bg-[#00703E] text-white rounded-full p-3 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </span>
                <h3 className="text-xl font-semibold">
                  {translator.txt65 || texts.txt65}
                </h3>
              </div>
              <p>{translator.txt66 || texts.txt66}</p>
            </div>

            <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="bg-[#00703E] text-white rounded-full p-3 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12H9m6-4l-6 4 6 4"
                    />
                  </svg>
                </span>
                <h3 className="text-xl font-semibold">
                  {translator.txt67 || texts.txt67}
                </h3>
              </div>
              <p>{translator.txt68 || texts.txt68}</p>
            </div>

            <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="bg-[#00703E] text-white rounded-full p-3 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                <h3 className="text-xl font-semibold">
                  {translator.txt69 || texts.txt69}
                </h3>
              </div>
              <p>{translator.txt70 || texts.txt70}</p>
            </div>

            <div className="bg-white text-gray-800 rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-4">
                <span className="bg-[#00703E] text-white rounded-full p-3 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12h6m-6 0l-4-4m4 4l-4 4"
                    />
                  </svg>
                </span>
                <h3 className="text-xl font-semibold">
                  {translator.txt71 || texts.txt71}
                </h3>
              </div>
              <p>{translator.txt72 || texts.txt72}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHams;
